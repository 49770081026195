.logo{
    /* width: 10vw; */
    margin-right: auto;
}
.logo img{
    width: 10vw; 
}
.link-active .list{
    color: '#3D2521';
    font-family: 'Bona Nova';
    font-size: 1.4rem;
    text-align: center;
    /* margin: auto; */
    text-decoration: none;
    justify-self: center;
    justify-content: center;
}
.link-active .list::after{
    bottom: 15px;
    content: "";
    display: block;
    height: 1.2px;
    left: 0.8em;
    position: absolute;
    background: var(--text);
    opacity: 0.9;
    transition: width 0.5s ease 0s, opacity 0.5s ease 0s;
    width: 1.5rem;
}
.link .list, .more{
    color: '#3D2521';
    font-family: 'Bona Nova';
    font-size: 1.4rem;
    text-align: center;
    /* margin: auto; */
    text-decoration: none;
    justify-self: center;
    justify-content: center;
}
.link .list::after{
    bottom: 15px;
    content: "";
    display: block;
    height: 1.2px;
    left: 0.8em;
    position: absolute;
    background: var(--text);
    opacity: 0.6;
    transition: width 0.5s ease 0s, opacity 0.5s ease 0s;
    width: 0rem;
}
.link .list:hover::after{
    opacity: 0.9;
    width: 1.5rem;
}
.Hmenu{
    position: absolute;
    top: 1vw;
    right: 0;
}
.MuiMenu-root ul{
    background:  #FFF6F3;
    box-shadow: none;
    border: none;
    border-radius: 0px;
}
.MuiMenu-root ul li{
    background: none;
    justify-content: center;
}
.MuiButtonBase-root .MuiTab-root{
    color: #3D2521;
    text-transform: none;
    font-size: 1rem;
    opacity: 1;
}
@media (max-width: 1050px) {
    .logo img{
        width: 10rem;
    }
    .link .list,.link-active .list, .more{
        font-size: 1rem;
    }
    .link-active .list::after{
        bottom: 15px;
        content: "";
        display: block;
        height: 1.2px;
        left: 1em;
        position: absolute;
        width: 1rem;
        opacity: 0.9;
    }
    .link .list::after{
        bottom: 15px;
        content: "";
        display: block;
        height: 1.2px;
        left: 1em;
        position: absolute;
        background: var(--text);
        opacity: 0.6;
        transition: width 0.5s ease 0s, opacity 0.5s ease 0s;
        width: 0rem;
    }
    .link .list:hover::after{
        opacity: 0.9;
        width: 1rem;
    }
}
@media (max-width: 1100px) {
    .link .list, .link-active .list,.more{
        font-size: 1.1rem;
        text-align: left;
    }
    .link-active .list::after{
        bottom: 12px;
        content: "";
        display: block;
        height: 1.2px;
        left: 1em;
        opacity: 0.9;
        width: 1.2rem;
    }
    .link .list::after{
        bottom: 12px;
        content: "";
        display: block;
        height: 1.2px;
        left: 1em;
        position: absolute;
        background: var(--text);
        opacity: 0.6;
        transition: width 0.5s ease 0s, opacity 0.5s ease 0s;
        width: 0rem;
    }
    .link .list:hover::after{
        opacity: 0.9;
        width: 1.2rem;
    }
}
@media (max-width: 970px) {
    .link .list, .link-active .list, .more{
        font-size: 0.9rem;
        text-align: left;
    }
}
@media (max-width: 900px) {
    .main-list{
        width: 100%;
        text-align: center;
        justify-items: center;
        align-items: center;
        top: 10vh;
    }
    .ham-list{
        font-family: 'Bona Nova';
        text-decoration: none;
        font-size: 1.2rem;
        text-align: center;
    }
    .sns-h{
        width: 2.2rem;
        height: 2.2rem;
        margin: 0.5rem;
    }
}
@media (max-width: 900px) and (max-height: 800px){
    .main-list{
        top: 5vh;
    }
}
@media (max-width:300px) {
    .logo img{
        width: 7rem;
    }
}