.footer{
    background: rgba(61, 37, 33, 0.5) url('../images/Footer-bg.png');
    background-repeat: no-repeat;
    background-blend-mode: overlay;
    background-position: center;
    background-size: cover;
    /* background-attachment: fixed; */
    box-shadow: inset 250px -250px 250px rgba(61, 37, 33, 0.9), inset -250px 250px 250px rgba(61, 37, 33, 0.9);
    margin-bottom: -4vh;
    color: var(--highlight);
}
.foot{
    width: 100%;
    display: flex;
    flex-direction: column;
    font-family: 'Bona Nova', serif;
    font-weight: 400;
    padding: 5vw 10vw 1vw 10vw;
    color: var(--highlight);
}
.f{
    /* width: 100vw; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.logo-white{
    width: 55%;
}
.company-info{
    display: flex;
    flex-direction: column;
    font-size: 0.8vw;
    width: 25%;
    text-align: left;
    margin-bottom: 2vw;
}
.sns{
    width: 2.4vw;
    height: 2.4vw;
    margin-right: 1vw;
}
a{
    text-decoration: none;
}
.Qlinks{
    /* width: 25%; */
    text-align: left;
    margin-top: 2vw;
}
.ft{
    /* font-size: 1.5vw; */
    font-weight: 400;
}
.ft::after{
    bottom: -0.2vw;
    content: "";
    display: block;
    height: 0.05vw;
    position: relative;
    background: var(--highlight);
    opacity: 0.8;
    width: 3.5vw;
}
.footnote{
    width: 100%;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    color: var(--highlight);
    border-top: 1px solid var(--highlight);
    padding-top: 0.5vw;
    margin-top: 1.2vw;
}
@media (max-width:900px) {
    .footer{
        box-shadow: inset 300px -300px 300px rgba(61, 37, 33, 0.9), inset -300px 300px 300px rgba(61, 37, 33, 0.9);
        background-position: left;
    }
    .logo-white{
        width: 12rem;
    }
    .sns{
        width: 2rem;
        height: 2rem;
        margin-right: 1rem;
    }
    .ft::after{
        bottom: -0.15rem;
        content: "";
        display: block;
        height: 0.1vw;
        position: relative;
        background: var(--highlight);
        opacity: 0.9;
        width: 2.5rem;
    }
    .footnote{
        align-items: center;
        text-align: center;
    }
}
@media (max-width:600px) {
    .footer{
        /* background: rgba(61, 37, 33, 0.5); */
        background-position: center center;
        box-shadow: inset 150px -150px 250px rgba(61, 37, 33, 0.9), inset -150px 150px 250px rgba(61, 37, 33, 0.9);
    }
    .logo-white{
        width: 11rem;
    }
    .sns{
        margin-top: 0.5rem;
    }
}