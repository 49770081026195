:root{
  --secondary: #3D2521;
  --main: #FFE2D8;
  --highlight: #FFBB70;
  --highlight2: #FF867C;
  --starColor: #F6B500;
  --text: #484848;
  --lightSecondary: #9E837D;
}
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.app{
  max-width: 100vw;
}
body{
  position: relative;
  /* padding-bottom: 30vw; */
  margin-bottom: 0;
  overflow-x: hidden;
}
/* @media (max-width:900px) {
  body{
    padding-bottom: 100vw;
  }
}
@media (max-width:700px) {
  body{
    padding-bottom: 90vw;
  }
}
@media (max-width:600px) {
  body{
    padding-bottom: 150vw;
  }
}
@media (max-width:500px) {
  body{
    padding-bottom: 200vw;
  }
} */