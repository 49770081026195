
.homepage{
    font-family:'Bona Nova', serif;
    font-weight: 400;
    color: var(--text);
}
.hero{
    background: url('../images/Hero.webp');
    width: 100vw;
    min-height: 55vw;
    background-repeat: no-repeat;
    background-size: 100vw;
    color: var(--secondary);
    display:"flex";
    flex-direction:"column";
    /* margin-top: -100px; */
}
/* .hero::before{
    content: "";
    background-color: var(--main);
    width: 100vw;
    height: 50vh;
    position: absolute;
    top: 50%;
    left: 0;
    clip-path: url('../../public/images/Vector\ 1.svg');
} */
.hero svg{
    position: absolute;
    top: 0vw;
    z-index: 2;
}
.hero-info{
    position: relative;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    top: 35vw;
    z-index: 10;
}
.hero-info img{
    width: 5%;
    margin-bottom: 2vw;
}
.hero-info h1{
    font-weight: 400;
    letter-spacing: 0.07em;
}

.title::after{
    content: "";
    bottom: 10px;
    display: block;
    height: 1.2px;
    right: -2vw;
    position: relative;
    background: #3D2521;
    opacity: 1;
    width: 5vw;
    text-align: right;
    justify-self: center;
    /* text-decoration: underline;
    text-underline-offset: -33%; */
}
.label:hover{
    color: #3D2521;
    transition: 0.5s;
}
.label.active{
    color: rgba(61, 37, 33);
}
.room-img img{
    /* display: flex; */
    width: 100%;
    height: auto;
    display: flex;
    border: none;
    border-radius: 20px 0px 0px 20px;                        
}
.slick-slider{
    margin-bottom: 2rem;
    width: 100%;
}
.slick-slider .slick-dots{
    display: flex;
    position: relative;
    flex-direction: row;
    left: -0.5vw;
    top: 0;
    /* bottom: -10; */
    /* transform: translateX(-50%); */
}
.slick-slider .slick-dots ul{
    list-style: none;
    margin: 0;
    padding: 0;
}
.slick-slider .slick-dots li{
    /* width: 3rem;
    height: 0.5vw;
    border-radius: 1vw; */
    /* background-color: #3D2521; */
    /* transform: scale(0.55); */
    /* width: 10px; */
    height: 0px;
    width: 0.9rem;
    margin: 0px;
}
.slick-dots li.slick-active{
    width: 1.4rem;
}
.slick-slider .slick-dots li button::before{
    width: 1.5rem;
    /* height: 1rem; */
    font-size: 0.5rem;
    content: ' ';
}
.slick-slider .slick-dots li button{
    display: inline-block;
    background: #C9A29C;
    width: 0.5rem;
    height: 0.5rem;
    /* height: 8px; */
    /* width: 35px; */
    overflow-y: clip;
    color: #C9A29C;
    border: none;
    border-radius: 4px;
    transform: scale(1);
    padding: 0;
    margin-right: "1rem";
    margin-left: "1rem";
}
.slick-dots li.slick-active button::before{
    width: 1rem;
    font-size: 0.5rem;
    content: ' ';
}
.slick-dots li.slick-active button{
    display: inline-block;
    /* background: #C9A29C; */
    width: 1.2rem;
    height: 0.5rem;
    overflow-y: clip;
    /* color: #C9A29C; */
    border: none;
    border-radius: 4px;
    /* transform: scale(0.5); */
    padding: 0;
    background: #3D2521;
    color: #3D2521;
    margin-right: "1rem";
    margin-left: "1rem",
}
.slick-slider {
    width: 100%;
}
.slick-slider .slick-track {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-x: scroll;
}
.slick-slider .slick-slide{
    /* width: 10vw; */
    margin-right: 2vw;
    /* height: 100%; */
    opacity: 0.8;
}
.slick-slider .card{
    box-shadow: none;
}
.slick-slider .slick-center .card{
    background-color: white;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.09), 0px 2px 5px rgba(0, 0, 0, 0.1);
}
.slick-slider .slick-center{
    opacity: 1;
}
.center{
    display: flex;
}
@media (max-width: 900px){
    .hero-info img{
        width: 10%;
    }
    .hero-info{
        top: 50vw;
    }
    .room-img img{
        border-radius:  20px 20px 0px 0px;
    }
}
@media (max-width: 900px){
    .hero-info img{
        width: 12%;
    }
}